import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import Logo from "../logo/Logo";
// import footerBg from "../../assets/images/img3.jpg";
// import ScrollButton from "../layout/ScrollButton";

import { FaLinkedin, FaTwitter, FaFacebook } from "react-icons/fa";

import { StyledContainer, StyledSection } from "../layout/Layouts";

export default function Footer({ bgColor }) {
  return (
    <StyledFooter bgColor={bgColor}>
      <StyledContainer>
        <StyledSection>
          <Logo size="large" />
          <div className={"footer-nav-list"}>
            <div className={"footer-nav-group"}>
              <h3>About</h3>
              <Link to={"/about"}>Mission </Link>
              <Link to={"/about/#vision"}>Vision</Link>
              <Link to={"/about/#coreValues"}>Core values</Link>
              <Link to={"/about/#business"}></Link>
            </div>
            <div className={"footer-nav-group"}>
              <h3>Services</h3>
              <Link to={"/services#engineering"}>Training/Seminars</Link>
              <Link to={"/services#agricultural"}>
                Farm Setup and Consultancy services
              </Link>
              <Link to={"/services#business"}>
                Hatchery Setup and Management
              </Link>
            </div>
            <div className={"footer-nav-group"}>
              <h3>Products</h3>
              <Link to={"/services#business"}>Farm Management Software</Link>
              <Link to={"/services#engineering"}>Incubators</Link>
              <Link to={"/services#agricultural"}>Cage Systems</Link>
            </div>
          </div>
          <div className={"media-handles"}>
            <a
              href="https://www.linkedin.com/company/cad-consulting-ltd"
              target="_blank"
              rel="noreferrer">
              <FaLinkedin />
            </a>
            <a
              href="https://web.facebook.com/SSEDC"
              target="_blank"
              rel="noreferrer">
              <FaFacebook />
            </a>
            <FaTwitter />
            {/* <ScrollButton /> */}
          </div>
        </StyledSection>
      </StyledContainer>
    </StyledFooter>
  );
}

export const StyledFooter = styled.footer`
  background-color: ${(props) => (props.bgColor ? props.bgColor : "#004c00")};
  min-height: 300px;
  width: 100%;
  /* background-image: url('https://ik.imagekit.io/6mmfyfoux/site/img3.jpg?tr=w-500'); */
  background-size: cover;
  /* background-blend-mode: multiply; */
  background-position: center;
  clip-path: polygon(
    0% 0%,
    47% 0%,
    50% 4%,
    53% 0%,
    100% 0%,
    100% 100%,
    0% 100%
  );

  @media screen and (max-width: 768px) {
    clip-path: polygon(
      0% 0%,
      47% 0%,
      50% 2%,
      53% 0%,
      100% 0%,
      100% 100%,
      0% 100%
    );
  }

  .media-handles {
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
    cursor: pointer;
    color: ${(props) => (props.bgColor ? "#004c006" : "#fff")};
    font-size: 3rem;
    margin-top: 40px;
  }

  .footer-nav-list {
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    gap: 40px;

    .footer-nav-group {
      display: flex;
      flex-direction: column;
      gap: 15px;
      /* width: 30%; */
      font-size: 1.4rem;

      h3 {
        font-weight: bold;
        font-size: 1.7rem;
        color: red;
        margin-bottom: 10px;
      }
      a {
        text-decoration: none;
        color: ${(props) => (props.bgColor ? "#004c00" : "#fff")};
        width: fit-content;
        &:hover {
          opacity: 0.6;
        }
      }
    }
  }
`;
