import { Link } from "react-router-dom";
import styled from "@emotion/styled";

const NavList = (props) => {
  const paths = window.location.pathname.split("/");
  let route = paths[paths.length - 1];
  route = !route ? "HOME" : route;
  return (
    <StyledNavList {...props}>
      <Link
        to={"/home"}
        className={`nav-link ${
          route.toUpperCase() === "HOME" && "current-page"
        }`}>
        HOME <br />
        <p style={{ fontSize: "9px" }}> Digital Farm</p>
      </Link>
      <Link
        to={"/about"}
        className={`nav-link ${
          route.toUpperCase() === "ABOUT" && "current-page"
        }`}>
        ABOUT US <br />
        <p style={{ fontSize: "9px" }}>About Us</p>
      </Link>
      <Link
        to={"/products"}
        className={`nav-link ${
          route.toUpperCase() === "PRODUCTS" && "current-page"
        }`}>
        PRODUCTS
        <br />
        <p style={{ fontSize: "9px" }}>Our Products</p>
      </Link>
      <Link
        to={"/services"}
        className={`nav-link ${
          route.toUpperCase() === "SERVICES" && "current-page"
        }`}>
        OUR SERVICES
        <br />
        <p style={{ fontSize: "9px" }}>What We Offer</p>
      </Link>
      <Link
        to={"/gallery"}
        className={`nav-link ${
          route.toUpperCase() === "GALLERY" && "current-page"
        }`}>
        PHOTO GALLERY
        <br />
        <p style={{ fontSize: "9px" }}>Our Gallery</p>
      </Link>
      <Link
        to={"/contact"}
        className={`nav-link ${
          route.toUpperCase() === "CONTACT" && "current-page"
        }`}>
        CONTACT US
        <br />
        <p style={{ fontSize: "9px" }}>For Enquiries</p>
      </Link>
    </StyledNavList>
  );
};

export default NavList;

const StyledNavList = styled.ul`
  display: flex;
  gap: ${(props) => (props.type === "desktop" ? "24px" : "32px")};
  flex-direction: ${(props) => (props.type === "desktop" ? "row" : "column")};

  .nav-link {
    font-family: "Poppins", sans-serif;
    letter-spacing: 2px;
    color: ${(props) => (props.color ? props.color : "#fff")};
    color: ${(props) => (props.float === true ? "#004c00" : "#fff")};
    text-decoration: none;
    font-size: ${(props) => (props.type === "desktop" ? "14px" : "18px")};
    z-index: 2;
    position: relative;
    &::after {
      content: "";
      width: 100px;
      height: 100px;
      background-color: #009900;
      position: absolute;
      width: 0;
      height: 0;
      /* background-color: red; */
      border-radius: 50%;
      left: 10%;
      top: -15%;
      transform-origin: center center;
      z-index: -1;
      transition: all 0.3s ease-in;
    }
    &:hover {
      opacity: 0.5;
      &::after {
        width: 30px;
        height: 30px;
      }
    }
  }

  .current-page {
    &::after {
      width: 30px;
      height: 30px;
    }
  }

  @media screen and (max-width: 768px) {
    & {
      display: ${(props) => (props.type === "desktop" ? "none" : "flex")};
      flex-direction: ${(props) =>
        props.type === "desktop" ? "row" : "column"};
    }
  }
`;
