import styled from "@emotion/styled";
import { FaCheck } from "react-icons/fa";
import NavList from "./NavList";

const MobileNavList = (props) => {
  const { setOpen } = props;

  return (
    <StyledNavbar {...props}>
      <span className={"close-btn"} onClick={() => setOpen((prev) => !prev)}>
        <FaCheck />
      </span>
      <NavList />
    </StyledNavbar>
  );
};

export default MobileNavList;

const StyledNavbar = styled.div`
  position: absolute;
  width: 100%;
  height: 100vh;
  border-left: 10px solid #332c72;
  left: ${(props) => (props.open === true ? "0" : "150vw")};
  top: 0;
  background-color: #332c72ee;
  background-color: maroon;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
  transition: all 0.3s ease-in;
  z-index: 100;

  .close-btn {
    position: absolute;
    color: white;
    top: 2rem;
    right: 2rem;
    font-size: 24px;
    cursor: pointer;
  }

  .menu-icon {
    display: none;
  }
`;
