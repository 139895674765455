import React from "react";
import { useHistory } from "react-router-dom";
import CADFarm from "../../assets/images/cadfarm.png";
// import CADLogo from "../../assets/images/cad-logo.svg";
// import CADLogo from "../../assets/images/cadLogo.jpg";

import styled from "@emotion/styled";

// const CADLogo = "https://ik.imagekit.io/6mmfyfoux/site/cadLogo.jpg?tr=w-250";

const Logo = (props) => {
  const history = useHistory();
  const goHome = () => {
    return history.push("/");
  };
  return (
    <StyledLogo {...props} onClick={goHome}>
      <img
        src={CADFarm}
        className={"logo-img"}
        alt={"CAD-logo"}
        loading="lazy"
      />
      {props.type !== "iconOnly" && <span className={"logo-name"}></span>}
    </StyledLogo>
  );
};

const StyledLogo = styled.a`
  text-decoration: none;
  user-select: none;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: fit-content;

  > .logo-img {
    width: ${(props) =>
      props.size === "large"
        ? "86px"
        : props.size === "medium"
        ? "64px"
        : "120px"};
    @media screen and (max-width: 768px) {
      & {
        width: 88px;
      }
    }
  }

  > .logo-name {
    font-weight: bold;
    color: ${(props) => (props.color ? props.color : "#fff")};
    font-size: ${(props) =>
      props.size === "large"
        ? "24px"
        : props.size === "medium"
        ? "18px"
        : "14px"};

    @media screen and (max-width: 768px) {
      & {
        display: none;
      }
    }
  }
`;

export default Logo;
