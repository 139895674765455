import React from "react";
import styled from "@emotion/styled";
import { StyledContainer, StyledHeader, StyledPage } from "../layout/Layouts";
import Navbar from "../navbar/Navbar";
import Footer from "../footer/Footer";
import CircularLoader from "./CircularLoader";

const LoaderLazyFallback = () => {
  return (
    <StyledPage>
      <StyledHeader height={"200px"}>
        <StyledContainer>
          <Navbar className={"desktop-navbar"} />
        </StyledContainer>
      </StyledHeader>
      <StyledLoader>
        <CircularLoader/>
        {/* <div className=''>
          <div className='loader'></div>
        </div> */}
      </StyledLoader>
      <Footer />
    </StyledPage>
  );
};

const StyledLoader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
 
`;

export default LoaderLazyFallback;
