// import {} from "react-dom";
import React, { Suspense } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import LoaderLazyFallback from "./components/loader/LoaderLazyFallback";

// import _404Page from "./pages/404";
// import AboutPage from "./pages/AboutPage";
// import LandingPage from "./pages/LandingPage";
// import Services from "./pages/Services";
// import Team from "./pages/Team";
// import ProjectPage from "./pages/ProjectPage";
// import UnderConstruction from "./pages/UnderConstruction";
// import GalleryPage from "./pages/GalleryPage";
// import ContactPage from "./pages/ContactPage";
// import Products from "./pages/Products";
// import RequestProduct from "./components/layout/RequestProduct";
// // import Activities from "./pages/Activities";

const _404Page = React.lazy(() => import("./pages/404"));
const AboutPage = React.lazy(() => import("./pages/AboutPage"));
const LandingPage = React.lazy(() => import("./pages/LandingPage"));
const Services = React.lazy(() => import("./pages/Services"));
const Team = React.lazy(() => import("./pages/Team"));
const GalleryPage = React.lazy(() => import("./pages/GalleryPage"));
const ContactPage = React.lazy(() => import("./pages/ContactPage"));
const Products = React.lazy(() => import("./pages/Products"));
const RequestProduct = React.lazy(() =>
  import("./components/layout/RequestProduct")
);
const Activities = React.lazy(() => import("./pages/Activities"));

// /const Customer = React.lazy(() => import("./Customer.js"));

function App() {
  return (
    <Router>
      <Switch>
        <Suspense fallback={<LoaderLazyFallback />}>
          <Route exact path={"/"} component={LandingPage} />
          <Route exact path={"/home"} component={LandingPage} />
          <Route exact path={"/about"} component={AboutPage} />
          <Route exact path={"/services"} component={Services} />
          <Route exact path={"/team"} component={Team} />
          <Route exact path={"/products"} component={Products} />
          <Route exact path={"/gallery"} component={GalleryPage} />
          <Route exact path={"/contact"} component={ContactPage} />
          <Route
            exact
            path={"/activities/:activityCode"}
            component={Activities}
          />
          <Route exact path={"/request-product"} component={RequestProduct} />
        </Suspense>
        <Route exact path={"*"} component={_404Page} />
      </Switch>
    </Router>
  );
}

export default App;
